import React, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { useAuth } from "../contexts/authcontext"
import CartContext from "../contexts/cartcontext"
import firebase from "gatsby-plugin-firebase"

const CartContainer = styled.div`
  width: 32.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1rem;
  background: var(--shaded);
  border-radius: 1rem;
  border: 0.05rem solid var(--gold);
  box-shadow: 0.5rem 0.5rem 0.5rem rgba(65, 36, 86, 0.5);
`

const CartGrid = styled.div`
  display: grid;
  grid-template-rows: subgrid(CartItemsGrid) 30rem;
  grid-auto-columns: auto;
  margin-top: 1rem;
  align-content: center;
  justify-content: center;
  .cartTtl {
    color: var(--gold);
    text-shadow: 0.2rem 0.2rem #412456;
  }
  .cartGST {
    font-size: 1.2rem;
    color: var(--gold);
  }
`

const CartItemsGrid = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-auto-columns: 1fr;
  align-items: center;
  justify-content: center;
  .gatsby-image-wrapper {
    border-radius: 1rem;
    border: 0.05rem solid var(--gold);
    box-shadow: 0.25rem 0.25rem 0.5rem 0.5rem rgba(65, 36, 86, 0.5);
    margin-bottom: 1rem;
  }
  .cartItemPrice {
    border-bottom: 0.05rem solid var(--gold);
    display: flex;
    align-items: center;
    margin-top: 0rem;
    h3 {
      padding-top: 0.4rem;
      color: var(--gold);
      text-shadow: 0.2rem 0.2rem #412456;
    }
    .remove {
      border-radius: 50%;
      border: 0.25rem solid var(--purple);
      background: transparent;
      font-size: 2rem;
      color: var(--lpurple);
      margin-bottom: 0.5rem;
      text-align: center;
      width: 2.5rem;
      cursor: pointer;
      :hover {
        color: var(--gold);
        border: 0.15rem solid var(--lpurple);
      }
    }
  }

  h4 {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0.75rem;
    color: var(--gold);
    text-shadow: 0.2rem 0.2rem #412456;
  }
`

export default function CartPurchase({ readings }) {
  const cntxt = useContext(CartContext)
  const [loading, setLoading] = useState(false)
  const cart = cntxt.cart
  const setCart = cntxt.setCart
  const cartTotal = cntxt.cartTotal
  const setCartTotal = cntxt.setCartTotal
  const setItemsPurchased = cntxt.setItemsPurchased
  const { currentUser } = useAuth()
  const [isCart, setIsCart] = useState(false)

  const removeFromCart = reading => {
    let copy = [...cart]
    copy = copy.filter(cartItem => cartItem.pricecode !== reading.pricecode)
    setCart(copy)
  }

  const cartItems = cart.map(reading => (
    <CartItemsGrid key={reading.pricecode}>
      <div className="m-0 mt-3 p-0 cartProduct">
        <h4>{`${reading.name}`}</h4>
      </div>
      <GatsbyImage
        image={reading.image.asset.gatsbyImageData}
        alt={reading.name}
        objectFit="contain"
        className="checkOutImg"
      />

      <div className="cartItemPrice">
        <h3>${`${reading.price}` / 100} </h3>

        <button
          className="remove d-flex justify-content-center ml-auto"
          disabled={loading}
          type="submit"
          value="X"
          onClick={() => removeFromCart(reading)}
        >
          X
        </button>
      </div>
    </CartItemsGrid>
  ))

  const total = () => {
    let totalVal = 0
    for (let i = 0; i < cart.length; i++) {
      totalVal += cart[i].price
    }
    setCartTotal(totalVal / 100)
    if (totalVal === 0) {
      setIsCart(false)
    } else {
      setIsCart(true)
    }
  }

  useEffect(() => {
    total()
  })

  async function checkOut(e) {
    e.preventDefault()
    setLoading(loading)
    const lineItems = cartItems.map(cartItem => ({
      price: `${cartItem.key}`,
      quantity: 1,
    }))
    setItemsPurchased(lineItems)

    const uid = currentUser.uid
    const displayName = currentUser.displayName
    localStorage.setItem("displayName", displayName)
    const purchases = cntxt.cart
    const time = Date.now()
    const readingFile = purchases.map(purchase => ({
      code: `${purchase.code}`,
      name: `${purchase.name}`,
      price: `${purchase.price}`,
      date: `${time}`,
    }))
    const userRef = firebase
      .firestore()
      .doc(`users/${uid}`)
      .collection(`readings`)
    try {
      const res = await userRef
        .add({
          ...readingFile,
        })
        .then(function (docRef) {
          sessionStorage.setItem("DocRef", docRef.id)
        })
    } catch (error) {
      console.error("Error adding data", error)
    } finally {
      setLoading(!loading)
      navigate("/purchase-finalise")
    }
  }

  return (
    <CartContainer>
      <CartGrid>
        {cartItems}
        <h3 className="mt-4 text-center cartTtl">Total: ${cartTotal}</h3>
        <h4 className="mb-4 text-center cartGST">
          incl. GST of ${(cartTotal - cartTotal / 1.1).toFixed(2)}
        </h4>
      </CartGrid>
      {isCart ? (
        <button
          className="checkOut"
          disabled={loading}
          type="submit"
          onClick={e => checkOut(e)}
        >
          Proceed To Checkout
        </button>
      ) : (
        <button
          className="checkOut"
          disabled={loading}
          type="button"
          onClick={() => {
            navigate("/readings")
          }}
        >
          Exit
        </button>
      )}
    </CartContainer>
  )
}
