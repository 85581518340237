import React from "react"
import styled from "styled-components"
import CartPurchase from "../components/cartpurchase"
import SEO from "../components/seo"

const StyledContainer = styled.div`
 
  width: 90%;
  margin: 2rem auto;
  display: flex;

`;


export default function PurchasePage() {
  return (
    <>
     <SEO title="Purchase" />
    <StyledContainer>
    <CartPurchase />
    </StyledContainer>
    </>
  )
}